import { Datetime } from "vue-datetime";
import { Settings } from "luxon";

export default {
  name: "OrderInProcessContentLayout",
  components: {
    datetime: Datetime,
  },
  data() {
    return {
      //VARIABLES
      overlay: false,
      sProviderId: "",
      aCategory: [],
      dataFile: {},
      bAddProducts: false,
      isdisrupted: false,
      isfinalized: false,
      sPurchaseOrderId: "",
      dTotalPallets: 0,
      dRatePerUSD: 0,
      panel: null,
      panelRecord: 0,
      sSalesOrderStatusRecordId: "",
      oPurchaseOrder: {},
      aFilesByStatus: [],
      PurchaseOrderStatusRecordImages: [],
      aSalesOrderGeneralFiles: [],
      aPurchaseOrderGeneralFiles: [],
      aPurchaseProductsOrigin: [],
      aPurchaseProducts: [],
      aUpdateaPurchaseProducts: [],
      aSalesOrderRecordFiles: [],
      aSalesOrderStatusRecord: [],
      SalesOrderFees: [],
      aUpdateSalesOrderFees: [],
      aStatusOrder: [],
      aOrderFilesByStatus: [],
      aOrderGeneralFiles: [],
      oPaymentData: {},
      oInOutFee: {},
      aInOutFee: [],
      isMobile: false,
      isTablet: false,
      isFixed: false,
      isCenter: false,
      imgWidthResponive: false,
      texts: "",
      e6: 1,

      sPaymentMethod: "",
      bPaymentMethod: null,
      bStatusPaid: false,
      bStatusCharged: false,

      aOrders: [],
      orderStatus: "",
      orderOriginLocation: "",
      orderDestinationLocation: "",
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      dialogDeletePO: false,
      locationMarkers: [],
      locPlaces: [],
      existingPlace: null,

      latOrigin: 0,
      lngOrigin: 0,
      latDestination: 0,
      lngDestination: 0,
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 12,
        minZoom: 3,
      },
      imgSelected: "",
      loadingCalculate: false,
      loadingChangeOrder: false,
      loadingChangeFees: false,
      loadingchangePaymentMethod: false,
      bUpdateStatus: false,
      value: 0,
      panel: 0,
      bImageEmty: false,
      menuDateStart: false,
      tArrivalAtBorderWarehouse: "",
      updateArrivalAtBorderWarehouse: false,
      placeholderArrivalAtBorderWarehouse: false,
      bDateExample: "",
      minDate: "", // min date value
      maxDate: "4000-01-01",
      optionsCurrency: {
        locale: "en-US",
        currency: "USD",
        currencyDisplay: "hidden",
        valueRange: {
          min: 1,
          max: 9999999,
        },
        precision: 2,
        hideCurrencySymbolOnFocus: true,
        hideGroupingSeparatorOnFocus: true,
        hideNegligibleDecimalDigitsOnFocus: true,
        autoDecimalDigits: false,
        autoSign: true,
        useGrouping: true,
        accountingSign: false,
        bLoadingUpdateStatus: false,
      },
      optionsTextFieldCurrency: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      optionsTextFieldPrencent: {
        locale: "en-US",
        prefix: "$",
        suffix: "",
        length: 11,
        precision: 2,
      },
      bDisabledCalculate: true,
      bDisabledSaveChangeProduct: true,
      bDisabledSaveChangeShippingBreakdown: true,
      bValPurchaseProductsOrigin: false,
    };
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize, { passive: true });
  },
  created() {},
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeMount() {
    // al momento de entrar al componente consulta el diccionario de lenguaje,  el detalle del pedido de venta, pinta la ubicacion en el mapa de la seccion del alamcen y el estatus de la orden
    this.texts = FILE.ordersTexts[this.selectLanguage];

    this.$store.commit("setMenuSidebar", { active: false });
    this.getFormatDateToMinDate();
    this.getOrdersInProcess();
    this.locateGeoLocation();
    this.getStatusOrder();
    this.$store.commit("setDialogEditFiles", {
      active: false,
      arr: [],
    });
  },
  methods: {
    validatebtnAddProducts: function() {
      // if (
      //   this.aOrders.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
      //   "6994aebc-106c-4f76-8a57-45e707cc22ba"
      // ) {
      //   this.bAddProducts = true;
      // } else {
      //   this.bAddProducts = false;
      // }
    },
    blockBtn(val) {
      this.bLoadingUpdateStatus = val;
      this.bUpdateStatus = val;
    },
    changeMontoGlobal1(obj) {
      this.aPurchaseProducts[obj.index].dDeliveredUnitPrice = parseFloat(
        obj.value
      );
    },
    changeMontoGlobal2(obj) {
      this.SalesOrderFees[obj.index].dBaseAmount = parseFloat(obj.value);
    },
    changeMontoGlobal3(obj) {
      this.SalesOrderFees[obj.index].dAmount = parseFloat(obj.value);
    },
    setDeleteItemProduct(sId, sIdSalesOrder) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.deleteProducts.title,
        descipcion: this.texts.deleteProducts.description,
        note: this.texts.deleteProducts.note,
        chkLabel: this.texts.deleteProducts.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${sIdSalesOrder}/products/${sId}`,
        return: false,
        dobleConfirm: false,
      });
    },
    getFormatDateToMinDate() {
      var date = new Date().toISOString().split("T")[0];
      this.minDate = date;
    },
    onResize() {
      this.isFixed = window.innerWidth < 960;
      this.isMobile = window.innerWidth < 600;
      this.isCenter = window.innerWidth > 600 && window.innerWidth < 960;
      this.isTablet = window.innerWidth > 959 && window.innerWidth < 1264;
    },
    openUpdateStatus() {
      this.dialogDeletePO = true;
    },
    finishOrder() {
      this.overlay = true;
      const params = {
        sSalesOrderStatusId: '4616a47e-b7cd-4f98-86c7-4b5a80363111',
        sSalesOrderId: this.$route.params.id
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/sales-orders/finishOrder`, params, config)
        .then((res) => {
          this.texts = FILE.ordersTexts[this.selectLanguage];

          this.$store.commit("setMenuSidebar", { active: false });
          this.getFormatDateToMinDate();
          this.getOrdersInProcess();
          this.locateGeoLocation();
          this.getStatusOrder();
          this.$store.commit("setDialogEditFiles", {
            active: false,
            arr: [],
          });
          this.dialogDeletePO = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    setCalculateChange() {
      this.loadingCalculate = true;
      let aPurchaseOrderProducts = [];
      aPurchaseOrderProducts = this.aPurchaseProducts
        .filter((item) => item.bLock === false)
        .map((e) => {
          return {
            sProductId: e.sProductId,
            dDeliveredQuantity: e.dDeliveredQuantity,
            dDeliveredUnitPriceClient: parseFloat(e.dDeliveredUnitPriceClient),
          };
        });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      const payload = {
        aPurchaseOrderProducts: aPurchaseOrderProducts,
      };
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.aOrders.oPurchaseOrder.sPurchaseOrderId}/products`,
        payload,
        config
      )
        .then((response) => {
          const aPurchaseProductsTemp = response.data.results;
          for (let i = 0; i < this.aPurchaseProducts.length; i++) {
            const oPurchaseProducts = this.aPurchaseProducts[i];
            for (let ii = 0; ii < aPurchaseProductsTemp.length; ii++) {
              const oPurchaseProductsTemp = aPurchaseProductsTemp[ii];
              if (
                oPurchaseProducts.sProductId ===
                oPurchaseProductsTemp.sProductId
              ) {

                oPurchaseProducts.dOrderedUnitPriceClient =
                  oPurchaseProductsTemp.dOrderedUnitPriceClient;

                oPurchaseProducts.dDeliveredUnitPriceClient =
                oPurchaseProductsTemp.dDeliveredUnitPriceClient;
                oPurchaseProducts.dFinalPrice =
                  oPurchaseProductsTemp.dFinalPrice;

                oPurchaseProducts.dDeliveredQuantity =
                  oPurchaseProductsTemp.dDeliveredQuantity;

                oPurchaseProducts.dDeliveredUnitPrice =
                  oPurchaseProductsTemp.dDeliveredUnitPrice;
                
                
                oPurchaseProducts.dFinalPriceClient =
                  oPurchaseProductsTemp.dFinalPriceClient;
                
                oPurchaseProducts.dOrderedQuantity =
                  oPurchaseProductsTemp.dOrderedQuantity;
                
                oPurchaseProducts.dOrderedUnitPrice =
                  oPurchaseProductsTemp.dOrderedUnitPrice;
                
                
                
                
              }
            }
          }
          // this.mixSuccess(response.data.message);
          this.loadingCalculate = false;
        })
        .catch((error) => {
          this.loadingCalculate = false;
          this.mixError(error.response.data.message, error.response.status);
          //           this.mixError(error.response.data.message,error.response.status);
        });
    },
    setValidatePurchaseProducts() {
      for (let i = 0; i < this.aPurchaseProductsOrigin.length; i++) {
        const ePurchaseProductsOrigin = this.aPurchaseProductsOrigin[i];
        const ePurchaseProducts = this.aPurchaseProducts[i];
        if (
          Number(ePurchaseProductsOrigin.dDeliveredQuantity) !==
            Number(ePurchaseProducts.dDeliveredQuantity) ||
          Number(ePurchaseProductsOrigin.dDeliveredUnitPriceClient) !==
            Number(ePurchaseProducts.dDeliveredUnitPriceClient)
        ) {
          this.bValPurchaseProductsOrigin = true;
          break;
        } else {
          this.bValPurchaseProductsOrigin = false;
        }
      }
    },
    setReversePurchaseProducts() {
      this.aPurchaseProducts = this.parseObjGlobal(
        this.aPurchaseProductsOrigin
      );
    },
    parseObjGlobal(obj) {
      return JSON.parse(JSON.stringify(obj));
    },

    // edicion de un archivo
    EditFiles: function(item) {
      this.dataFile = item;
      this.$store.commit("setDialogEditFiles", {
        active: true,
        arr: this.dataFile,
      });
    },
    AddProducts: function() {
      this.getCategories();
      this.$store.commit("setDialogAddProducts", {
        active: true,
        arr: [],
      });
    },
    getCategories: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/vendors/enterprises/${this.sProviderId}/categories`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        }
      )
        .then((response) => {
          this.aCategory = response.data.results;

          if (this.selectLanguage == "sp") {
            // this.aCategory.unshift({ sCategoryName: "Todos", sCategoryId: "" });
            // this.aSubCategory.unshift({sSubcategoryName:'Todos',sSubcategoryId:''})
          } else {
            // this.aCategory.unshift({ sCategoryName: "All", sCategoryId: "" });
            // this.aSubCategory.unshift({sSubcategoryName:'All',sSubcategoryId:''})
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // modificacion del estado del pedido de venta
    updateState: function() {
      if (this.bImageEmty) {
        this.mixError(
          this.texts.dialogUpdateStatus.youNeedToAddTheRequiredFiles
        );
      } else {
        this.$store.commit("setDialogUpdateStatus", {
          active: true,
          arr: this.aOrders,
        });
      }
    },
    initMarker(loc) {
      this.existingPlace = loc;
    },
    addLocationMarker() {
      if (this.existingPlace) {
        const marker = {
          lat: this.existingPlace.geometry.location.lat(),
          lng: this.existingPlace.geometry.location.lng(),
        };
        this.locationMarkers.push({ position: marker });
        this.locPlaces.push(this.existingPlace);
        this.center = marker;
        this.existingPlace = null;
      }
    },
    locateGeoLocation: function() {
      navigator.geolocation.getCurrentPosition((res) => {
        this.center = {
          lat: res.coords.latitude,
          lng: res.coords.longitude,
        };
      });
    },
    // convierte el numero en formato money
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    // obtiene el detalle del pedido de ventas
    getOrdersInProcess: function() {
      DB.get(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
          params: {},
        }
      )
        .then((response) => {
          this.aOrders = response.data.results;
          this.overlay = false;
          // this.sProviderId = this.aOrders.oVendorEnterprise.sEnterpriseId;
          // this.dTotalPallets = response.data.results.oInOutFee.dTotalPallets;
          this.dRatePerUSD = parseFloat(response.data.results.dRatePerUSD);
          this.validatebtnAddProducts();
          if (this.aOrders.tArrivalAtBorderWarehouse !== null) {
            (this.placeholderArrivalAtBorderWarehouse = true),
              (this.tArrivalAtBorderWarehouse = this.aOrders.tArrivalAtBorderWarehouse);
            this.tArrivalAtBorderWarehouse = this.tArrivalAtBorderWarehouse.replace(
              " ",
              "T"
            );
          }
          var i = 0;
          this.aFilesByStatus = response.data.results.aFilesByStatus.map(
            (e) => {
              i++;
              return {
                ...e,
                id: i,
              };
            }
          );
          // this.sPurchaseOrderId =
          //   response.data.results.oPurchaseOrder.sPurchaseOrderId;
          this.oPurchaseOrder = response.data.results.oPurchaseOrder;

          // this.PurchaseOrderStatusRecordImages = response.data.results.oPurchaseOrder.aOrderStatusRecord.map(
          //   (e) => {
          //     return {
          //       ...e,
          //       aImagesPrimary:
          //         e.aOrderStatusRecordImages.length > 0
          //           ? e.aOrderStatusRecordImages[0].oImages.md
          //           : "@/assets/images/EmptyImage.jpg",
          //       aImages: e.aOrderStatusRecordImages,
          //       sPurchaseOrderStatusName: e.sPurchaseOrderStatusName,
          //       sDescription: e.sDescription,
          //     };
          //   }
          // );
          this.panel = this.PurchaseOrderStatusRecordImages.length - 1;
          this.aSalesOrderGeneralFiles = response.data.results.aSalesOrderGeneralFiles.map(
            (e) => {
              return {
                ...e,
                loadingFile: false,
              };
            }
          );

          this.aPurchaseOrderGeneralFiles =
            response.data.results.aPurchaseOrderGeneralFiles;
          this.aPurchaseProductsOrigin = response.data.results.aPurchaseProducts.map(
            (e, i) => {
              return {
                ...e,
                index: i,
                bLock: true,
              };
            }
          );
          this.aPurchaseProducts = response.data.results.aPurchaseProducts.map(
            (e, i) => {
              return {
                ...e,
                index: i,
                bLock: true,
              };
            }
          );

          this.aSalesOrderRecordFiles =
            response.data.results.aSalesOrderRecordFiles;
          this.aSalesOrderStatusRecord =
            response.data.results.aSalesOrderStatusRecord;
          this.SalesOrderFees = response.data.results.SalesOrderFees.map(
            (e, i) => {
              return {
                ...e,
                index: i,
                bLock: true,
              };
            }
          );
          this.bImageEmty = false;
          this.aOrderFilesByStatus = response.data.results.oPurchaseOrder.aOrderFilesByStatus.map(
            (e, i) => {
              if (e.sFileKey === null) {
                this.bImageEmty = true;
              }
              return {
                ...e,
                i: i,
                loadingFile: false,
              };
            }
          );

          this.aOrderGeneralFiles = response.data.results.oPurchaseOrder.aOrderGeneralFiles.map(
            (e) => {
              return {
                ...e,
                loadingFile: false,
              };
            }
          );

          this.oInOutFee = {};
          this.aInOutFee = [];
          this.oInOutFee = response.data.results.oInOutFee;
          this.aInOutFee.push(this.oInOutFee);
          // this.isdisrupted =
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "27258f1d-3e87-4cc9-a548-2f4ebca26068" ||
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "6994aebc-106c-4f76-8a57-45e707cc22ba" ||
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "d995e23b-341f-4f6d-8f58-071cf762cc1c";
          // this.isfinalized =
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "18b984e7-2295-40d8-aaa1-8f4f89fdaed8" ||
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "38b51cdd-95ca-4870-acde-68ed35597adb";

          // this.bUpdateStatus =
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "dac89b64-079a-4c71-b894-49436e0b61d5" ||
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "18b984e7-2295-40d8-aaa1-8f4f89fdaed8" ||
          //   response.data.results.oPurchaseOrder.oStatusRecord.sOrderStatusId ==
          //     "38b51cdd-95ca-4870-acde-68ed35597adb";

          this.latOrigin =
            response.data.results.oOriginWarehouse.oCoordinates.dLatitude;
          this.lngOrigin =
            response.data.results.oOriginWarehouse.oCoordinates.dLongitude;
          this.latDestination =
            response.data.results.oDestinationWarehouse.oCoordinates.dLatitude;
          this.lngDestination =
            response.data.results.oDestinationWarehouse.oCoordinates.dLongitude;
          this.sSalesOrderStatusRecordId =
            response.data.results.oSalesOrderStatus.sSalesOrderStatusRecordId;
          this.orderStatus = response.data.results.sCurrentStatusName;
          this.oPaymentData = response.data.results.oPaymentData;
          this.bStatusCharged =
            response.data.results.oPaymentData.oStatus.bPaid;
          this.bStatusPaid = response.data.results.oPurchaseOrder.bCharged;

          this.bPaymentMethod =
            response.data.results.oPaymentData.oStatus.sPaid === "No pagado" ||
            response.data.results.oPaymentData.oStatus.sPaid === "Unpaid";
          // this.orderOriginLocation = response.data.results.oOrigin.sLocation;
          // this.orderDestinationLocation =
          //   response.data.results.oDestination.sLocation;
          // this.tArrivalAtBorderWarehouse = this.aOrders.tArrivalAtBorderWarehouse.substr('0','16')
          this.updateArrivalAtBorderWarehouse =
            response.data.results.oSalesOrderStatus.sStatusId ===
              "4616a47e-b7cd-4f98-86c7-4b5a80363111" ||
            response.data.results.oSalesOrderStatus.sStatusId ===
              "6069dc17-74c3-4757-b533-2af4a853322d";
          this.bLoadingUpdateStatus = false;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // obtiene los estatus del pedido de venta
    getStatusOrder: function() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/status/purchase-orders`, {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
        params: {},
      })
        .then((response) => {
          let id = 0;
          this.aStatusOrder = response.data.results.map((e) => {
            return {
              ...e,
              id: (++id).toString(),
            };
          });
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },

    // manda al detalle del vendedor que se encuentra en el detalle del pedido de venta
    detailVendor: function(id) {
      this.$router.push(`/admin/vendor-detail/${id}`);
    },
    // manda al detalle del comprador que se encuentra en el detalle del pedido de venta
    detailCustomer: function(id) {
      this.$router.push(`/admin/customer-detail/${id}`);
    },

    // muestra la imagen que se selecciono en la seccion de seguimiento
    selectedimg(img) {
      this.imgSelected = img.xlg;
    },
    // ALTA DE ARCHIVOS DE PROVEEDOR
    // acciona el input que se selecciono en base a su id
    clickInputFileVendor: function(id, loadingFile) {
      var inputUploadFile = "inputUploadFileVendor" + id;
      document.getElementById(inputUploadFile).click();
    },
    // agrega el documento que se selecciono
    uploadFilesVendor: function(e, item) {
      item.loadingFile = true;
      let idFile = e.target.getAttribute("attr-file");
      const file = e.target.files[0];
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      const payload = {};

      var form = new FormData();
      form.append("file", file);

      //AQUI SE SUBE EL ARCHIVO
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.sPurchaseOrderId}/files/${idFile}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          item.loadingFile = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // remueve el documento que se selecciono
    removeItemVendor: function(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .title,
        descipcion: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .description,
        note: this.texts.detailOrder.vendorAndCustomerFilesSection.delete.note,
        chkLabel: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.sPurchaseOrderId}/files/${item.sPurchaseOrderFileTypeId}`,
        return: false,
        dobleConfirm: false,
      });
    },

    // ALTA DE ARCHIVOS DE CUSTOMER
    // acciona el input que se selecciono en base a su id
    clickInputFileCustomer: function(id) {
      var inputUploadFile = "inputUploadFileCustomer" + id;
      document.getElementById(inputUploadFile).click();
    },
    // agrega el documento que se selecciono
    uploadFilesCustomer: function(e, item) {
      let idFile = e.target.getAttribute("attr-file");
      const file = e.target.files[0];
      item.loadingFile = true;

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      var form = new FormData();
      form.append("file", file);

      //AQUI SE SUBE EL ARCHIVO
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/files/${idFile}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);

          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          item.loadingFile = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // remueve el documento que se selecciono
    removeItemCustomer: function(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .title,
        descipcion: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .description,
        note: this.texts.detailOrder.vendorAndCustomerFilesSection.delete.note,
        chkLabel: this.texts.detailOrder.vendorAndCustomerFilesSection.delete
          .chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/files/${item.sSalesOrderFileTypeId}`,
        return: false,
        dobleConfirm: false,
      });
    },

    //ALTA DE ARCHIVOS REQUERIDOS Y ELIMINADO DE ARCHVIOS REQUERIDOS
    // acciona el input que se selecciono en base a su id
    clickInputFile: function(id) {
      var inputUploadFile = "inputUploadFile" + id;
      document.getElementById(inputUploadFile).click();
    },
    // Guarda el nombre del archivo
    saveFiles: function(e, item) {
      item.loadingFile = true;

      let idFile = e.target.getAttribute("attr-file");
      const file = e.target.files[0];

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      //AQUI SE SUBE EL ARCHIVO
      DB.post(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.aOrders.oPurchaseOrder.sPurchaseOrderId}/records/${this.aOrders.oPurchaseOrder.oStatusRecord.sOrderStatusRecordId}/files/${idFile}`,
        payload,
        config
      )
        .then((response) => {
          this.uploadFiles(idFile, file, item);
        })
        .catch((error) => {
          item.loadingFile = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // guarda el archivo seleccionado
    uploadFiles: function(idFile, file, item) {
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      var form = new FormData();
      form.append("file", file);

      //AQUI SE SUBE EL ARCHIVO
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.aOrders.oPurchaseOrder.sPurchaseOrderId}/records/${this.aOrders.oPurchaseOrder.oStatusRecord.sOrderStatusRecordId}/files/${idFile}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);

          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          item.loadingFile = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // remueve el archivo seleccionado
    removeItem: function(item) {
      this.$store.commit("setDelete", {
        active: true,
        title: this.texts.detailOrder.requiredFilesSection.delete.title,
        descipcion: this.texts.detailOrder.requiredFilesSection.delete
          .description,
        note: this.texts.detailOrder.requiredFilesSection.delete.note,
        chkLabel: this.texts.detailOrder.requiredFilesSection.delete.chkLabel,
        api: `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.aOrders.oPurchaseOrder.sPurchaseOrderId}/records/${this.aOrders.oPurchaseOrder.oStatusRecord.sOrderStatusRecordId}/files/${item.sPurchaseOrderFileTypeId}`,
        return: false,
        dobleConfirm: false,
      });
    },

    // descarga los archivos seleccionados
    downloadFile: function(sFileUrl) {
      window.open(sFileUrl, "blank_");
    },

    // guarda los cambios dentro de la seccion del pedido de venta y comisiones
    // acciona el evento que guarda las unidades y precios del pedido de venta
    saveChangeOrders: function() {
      this.saveChangeOrderProducts();
    },
    // guarda la unidad final y el precio por unidad final del pedido de venta
    saveChangeOrderProducts: function() {
      this.loadingChangeOrder = true;
      let aPurchaseOrderProducts = [];
      aPurchaseOrderProducts = this.aPurchaseProducts
        .filter((item) => item.bLock === false)
        .map((e) => {
          return {
            sProductId: e.sProductId,
            dDeliveredQuantity: e.dDeliveredQuantity,
            dDeliveredUnitPriceClient: parseFloat(e.dDeliveredUnitPriceClient),
          };
        });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      const payload = {
        aPurchaseOrderProducts: aPurchaseOrderProducts,
      };
      DB.put(
        `${URI}/api/v1/${this.selectLanguage}/purchase-orders/${this.aOrders.oPurchaseOrder.sPurchaseOrderId}/products`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.loadingChangeOrder = false;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.loadingChangeOrder = false;

          this.mixError(error.response.data.message, error.response.status);
          //           this.mixError(error.response.data.message,error.response.status);
        });
    },
    // guarda el monto base ,comision y tarifa fija del pedido de venta
    saveChangeFees: function() {
      this.loadingChangeFees = true;
      let aSalesOrderFees = [];
      aSalesOrderFees = this.SalesOrderFees.filter(
        (item) => item.bLock === false
      ).map((e) => {
        return {
          sFeeId: e.sFeeId,
          dBaseAmount: e.dBaseAmount,
          dAmount: e.dAmount,
          dPercentage: e.dPercentage,
        };
      });

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };
      const payload = {
        aFees: aSalesOrderFees,
      };
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/fees`,
        payload,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.loadingChangeFees = false;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.loadingChangeFees = false;
          //           this.mixError(error.response.data.message,error.response.status);
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // Modifica el metodo de pago del pedido de venta
    changePayMethod: function() {
      this.$store.commit("setDialogChangePaymentMethod", true);
    },
    changePaymentMethod: function() {
      this.$store.commit("setDialogPayMethod", true);

      // this.loadingchangePaymentMethod = true;
      // const config = {
      //   headers: {
      //     Authorization: `Bearer ${this.$store.state.sToken}`,
      //   },
      // }
      // const payload = {
      //   bPaid: true
      // };
      // DB.patch(
      //   `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/paid`,
      //   payload, config
      // )
      //   .then((response) => {
      //     // this.loadingchangePaymentMethod = false;
      //     this.mixSuccess(response.data.message);
      //     this.$store.commit("refresher", true);
      //   })
      //   .catch((error) => {
      //     this.loadingchangePaymentMethod = false;
      //     //           this.mixError(error.response.data.message,error.response.status);
      //     this.mixError(error.response.data.message, error.response.status);
      //   });
    },
    // // Modifica la fecha estimada de entrega del pedido de venta
    // changeDateArrival: function() {
    //   this.$store.commit("setDialogDateArrival", {
    //     active: true,
    //     sDate: this.$refs.dateArrival.inputValue,
    //   });
    // },

    //  esta funcion regresa puros numeros
    soloNumbers: function(evt) {
      if (
        (evt.which != 8 && evt.which != 0 && evt.which < 48) ||
        evt.which > 57
      ) {
        evt.preventDefault();
      }
    },
    // valida que no pase el 100%
    fieldPorcentage: function(evt) {
      if (evt.target.value > 100) {
        evt.target.value = 100;
      }
    },
    // Valida si esta vacio el campo lo convierte en 0 la unidad de medida
    fieldValidatonDeliveredQuantity(item) {
      if (item.dDeliveredQuantity == "") {
        item.dDeliveredQuantity = 0;
      }
    },
    // Valida que si esta vacio el campo lo convierte en 0 el precio final
    fieldValidatondDeliveredUnitPrice(item) {
      if (item.dDeliveredUnitPrice == "") {
        item.dDeliveredUnitPrice = 0;
      }
    },
    // Valida que si esta vacio el campo lo convierte en 0 el monto base
    fieldValidatondBaseAmount(item) {
      if (item.dBaseAmount == "") {
        item.dBaseAmount = 0;
      }
    },
    // Valida que si esta vacio el campo lo convierte en 0 el porcentaje
    fieldValidatondPercentage(item) {
      if (item.dPercentage == "") {
        item.dPercentage = 0;
      }
    },
    // Valida que si esta vacio el campo lo convierte en 0 la tarifa fija
    fieldValidatondAmount(item) {
      if (item.dAmount == "") {
        item.dAmount = 0;
      }
    },
    changeDateArrival() {
      setTimeout(() => {
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };
        let aDate = document
          .getElementsByClassName("vdatetime-input")[0]
          .value.split("-");

        let day = aDate[0];
        let month = aDate[1];
        let year = aDate[2].split(" ")[0];
        let time = aDate[2].split(" ")[1];
        let date = year + "-" + month + "-" + day + " " + time;
        const payload = {
          tArrivalAtBorderWarehouse: date,
        };
        DB.patch(
          `${URI}/api/v1/${this.selectLanguage}/sales-orders/${this.$route.params.id}/arrival`,
          payload,
          config
        )
          .then((response) => {
            this.mixSuccess(response.data.message);

            this.$store.commit("refresher", true);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
      }, 100);
    },
    openCarousel: function(imgSelected, allImages) {
      this.$store.commit("setGallery", {
        active: true,
        imageSelected: imgSelected,
        imagesArr: allImages,
      });
    },
  },
  computed: {
    selectLanguage: function() {
      return this.$store.state.language;
    },
    refresh: function() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function() {
      this.selectLanguage == "sp"
        ? (Settings.defaultLocale = "es")
        : (Settings.defaultLocale = "en");
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
        this.getOrdersInProcess();
      }
    },
    refresh: function() {
      this.getOrdersInProcess();
    },
    tArrivalAtBorderWarehouse() {
      if (this.tArrivalAtBorderWarehouse !== null) {
        this.placeholderArrivalAtBorderWarehouse = true;
      }
      // this.changeDateArrival(this.$refs.dateArrival.inputValue)
    },
    aPurchaseProducts: {
      deep: true,
      handler() {
        this.setValidatePurchaseProducts();
        for (let i = 0; i < this.aPurchaseProducts.length; i++) {
          const element = this.aPurchaseProducts[i];
          if (!element.bLock) {
            this.bDisabledCalculate = false;
            this.bDisabledSaveChangeProduct = false;
            break;
          } else {
            this.bDisabledCalculate = true;
            this.bDisabledSaveChangeProduct = true;
          }
        }
      },
    },
    SalesOrderFees: {
      deep: true,
      handler() {
        for (let i = 0; i < this.SalesOrderFees.length; i++) {
          const element = this.SalesOrderFees[i];
          if (!element.bLock) {
            this.bDisabledSaveChangeShippingBreakdown = false;
            break;
          } else {
            this.bDisabledSaveChangeShippingBreakdown = true;
          }
        }
      },
    },
  },
};
